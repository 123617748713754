import { Indication } from './indication';

export class IndicationList {
    static readonly INSECTICIDES = 1;
    static readonly HERBICIDES = 2;
    static readonly FUNGICIDES = 3;
    static readonly SEEDS = 4;
    static readonly FERTILIZERS = 5;
    static readonly SPECIALITIES = 6;
    static readonly VARIETY_TRAITS = 7;
    static readonly NEMATODES = 8;

    private readonly indicationList: Indication[] = [];

    constructor() {
        this.fillList();
    }

    getCodeList(): string[] {
        const codeList: string[] = [];
        for (const indication of this.indicationList) {
            codeList.push(indication.getCode());
        }
        return codeList;
    }

    getIdFromCode(code: string): number | undefined {
        const indicationFound = this.indicationList.find(indication => indication.getCode() === code);
        if (indicationFound) {
            return indicationFound.getId();
        }
    }

    getCodeFromId(id: number): string | undefined {
        const indicationFound = this.indicationList.find(indication => indication.getId() === id);
        if (indicationFound) {
            return indicationFound.getCode();
        }
    }

    getCodeFromName(name: string): string | undefined {
        const indicationFound = this.indicationList.find(indication => indication.getName() === name);
        if (indicationFound) {
            return indicationFound.getCode();
        }
    }

    getIndication(id: number): Indication | undefined {
        return this.indicationList.find(indication => indication.getId() === id);
    }

    private fillList() {
        this.indicationList.push(new Indication('I', 'INSECTICIDES', IndicationList.INSECTICIDES));
        this.indicationList.push(new Indication('H', 'HERBICIDES', IndicationList.HERBICIDES));
        this.indicationList.push(new Indication('F', 'FUNGICIDES', IndicationList.FUNGICIDES));
        this.indicationList.push(new Indication('S', 'SEEDS', IndicationList.SEEDS));
        this.indicationList.push(new Indication('D', 'FERTILIZER', IndicationList.FERTILIZERS));
        this.indicationList.push(new Indication('P', 'SPECIALITIES', IndicationList.SPECIALITIES));
        this.indicationList.push(new Indication('V', 'VARIETY_TRAITS', IndicationList.VARIETY_TRAITS));
        this.indicationList.push(new Indication('N', 'NEMATODES', IndicationList.NEMATODES));
    }

}
